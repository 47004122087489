export default function App() {
  return (
    <main className="flex flex-col items-center justify-center min-h-screen bg-pattern bg-repeat bg-contain relative">
      <div className="flex flex-col lg:flex-row items-center justify-center pb-64 w-full px-4 pt-12 sm:px-6 lg:px-8">
        <div className="w-full lg:w-1/3 z-10 flex flex-col lg:justify-center ">
          <div className="w-full mb-8 md:mb-12 mx-auto flex items-center flex-col">
            <img
              alt="Nosher - Food Ingredient Label Scanner"
              className="h-auto w-1/4 md:mt-24"
              src="/noshericonsquare.png"
            ></img>
            <h1 className="text-3xl font-bold font-gilroy text-center text-gray-900 mt-8">
              Nosher
            </h1>
            <h2 className="mt-2 text-2xl text-center text-gray-600">
              Scan ingredient labels on food packages using AI.
            </h2>
          </div>
          <div className="mb-8 md:mb-24 mx-auto flex items-center">
            <div className="flex space-x-4 items-center justify-center">
              <a className="w-1/3" href="https://apps.apple.com/app/id6473447642">
                <img
                  alt="Nosher - Food Ingredient Label Scanner"
                  src="/appstore.png"
                ></img>
              </a>
              <a className="w-1/3" href="https://play.google.com/store/apps/details?id=com.nosher">
                <img
                  alt="Nosher - Food Ingredient Label Scanner"
                  src="/playstore.png"
                ></img>
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2 xl:w-1/3 z-10 overflow-hidden">
          <div className="flex justify-center overflow-hidden ">
            <img
              alt="Mobile phone mockup"
              className="w-2/3"
              src="/screenshotnosher.png"
            />
          </div>
        </div>
      </div>
      <footer className="h-24 w-full items-start flex justify-center absolute bottom-0">
        <div className="px-12 flex flex-row bg-orange-600 py-2 rounded-3xl">
          <div className="text-white font-gilroy text-xl font-bold mr-1 mt-0.5">Contact: </div>
          <a className="text-white text-xl underline cursor-pointer" href="mailto:info@nosherapp.com">
            info@nosherapp.com
          </a>
        </div>
      </footer>
    </main>
  );
}